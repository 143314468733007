export function recruit(rest){
	const compornent = {
		data: function(){
			return {
				toggle: false,
				data:  [],
				detail: []
			}
		},
		created: function(){
            const $_this = this;
            axios.get(rest)
                .then(function (response) {
                    $_this.data = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
		},
		methods: {
			open : function(index){
				this.detail = this.data[index];
				this.toggle = true;
			},
			changing: function(){
				const body = document.getElementById('site-body');
				const head = document.getElementById('site-header');
				body.classList.toggle('hidden');
				head.classList.toggle('hidden');
			}
		},
		template:
		`
		<div>
			<ul class="list clearfix">
				<li @click="open(index) , changing()" v-for="(items , index) in data">
					<i class="job" v-text="items.acf.cate"></i>
					<div class="bg" :style="{ 'background-image': 'url('+items.acf.main+')' }"></div>
					<dl>
						<dt v-text="items.title.rendered"></dt>
						<dd class="border" v-html="items.acf.catch"></dd>
					</dl>
					<ul class="meta">
						<li class="desc" data-text="給与" v-text="items.acf.money"></li>
						<li class="desc" data-text="勤務地" v-html="items.acf.area"></li>
					</ul>
				</li>
			</ul>
            <transition name="move">
				<div class="overlay white" v-if="toggle">
					<div class="close" @click="toggle = false , changing()"></div>
					<div class="inner recruit-component">
						<div class="contanier">

							<dl class="mes">
								<dt v-text="detail.title.rendered"></dt>
								<dd v-html="detail.acf.catch"></dd>
							</dl>

							<ul class="tag">
								<li v-for="tag in detail.acf.tag" v-text="tag"></li>
							</ul>

							<h2><i class="fas fa-comment-dots rvs"></i>企業からのPRメッセージ</h2>
							<div class="mainphoto" :style="{ 'background-image': 'url('+detail.acf.main+')' }"></div>

							<p class="text" v-html="detail.acf.message"></p>

							<h2><i class="fas fa-clipboard-list"></i>募集要項</h2>
							<table class="table-style">
								<tbody>
									<tr>
										<th>仕事内容</th>
										<td v-html="detail.acf.work"></td>
									</tr>
									<tr>
										<th>求める人</th>
										<td v-html="detail.acf.human"></td>
									</tr>
									<tr>
										<th>雇用形態</th>
										<td v-html="detail.acf.cate"></td>
									</tr>
									<tr>
										<th>研修期間</th>
										<td v-html="detail.acf.traning"></td>
									</tr>
									<tr>
										<th>給与</th>
										<td v-html="detail.acf.money"></td>
									</tr>
									<tr>
										<th>エリア</th>
										<td v-html="detail.acf.area"></td>
									</tr>
									<tr>
										<th>アクセス</th>
										<td v-html="detail.acf.access"></td>
									</tr>
									<tr>
										<th>勤務時間</th>
										<td v-html="detail.acf.time"></td>
									</tr>
									<tr>
										<th>待遇・福利厚生</th>
										<td v-html="detail.acf.fukuri"></td>
									</tr>
									<tr>
										<th>休日・休暇</th>
										<td v-html="detail.acf.holiday"></td>
									</tr>
								</tbody>
							</table>
							<ul class="photos">
								<li>
									<div class="bg" :style="{ 'background-image': 'url('+detail.acf.img_01+')' }"></div>
									<p class="caption" v-html="detail.acf.caption_01"></p>
								</li>
								<li>
									<div class="bg" :style="{ 'background-image': 'url('+detail.acf.img_02+')' }"></div>
									<p class="caption" v-html="detail.acf.caption_02"></p>
								</li>
								<li>
									<div class="bg" :style="{ 'background-image': 'url('+detail.acf.img_03+')' }"></div>
									<p class="caption" v-html="detail.acf.caption_03"></p>
								</li>
							</ul>
							<slot></slot>
						</div>		
					</div>
				</div>
			</transition>
		</div>
		`
	}
	
	return compornent;
}