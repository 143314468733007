export function voice(rest){
	const compornent = {
		data: function(){
			return {
				toggle: false,
				data:  [],
				detail: []
			}
		},
		created: function(){
            const $_this = this;
            axios.get(rest)
                .then(function (response) {
                    $_this.data = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
		},
		methods: {
			open : function(index){
				this.detail = this.data[index];
				this.toggle = true;
			},
			changing: function(){
				const body = document.getElementById('site-body');
				const head = document.getElementById('site-header');
				body.classList.toggle('hidden');
				head.classList.toggle('hidden');
			}
		},
		template:
		`
		<div>
            <ul class="list clearfix">
                <li @click="open(index) , changing()" v-for="(items , index) in data">
                    <div class="circle" :style="{ 'background-image': 'url('+items.acf.photo+')' }"></div>
                    <dl>
                        <dd v-text="items.acf.busyo"></dd>
                        <dt v-text="items.title.rendered"></dt>
                    </dl>
                </li>
            </ul>
            <transition name="move">
				<div class="overlay white" v-if="toggle">
					<div class="close" @click="toggle = false , changing()"></div>
					<div class="inner voice-component">
						<div class="contanier">
							<div class="profile">
								<div class="bg" :style="{ 'background-image': 'url('+detail.acf.photo+')' }"></div>
								<dl>
									<dd class="job" v-text="detail.acf.busyo"></dd>
									<dd class="date" v-text="detail.acf.day"></dd>
									<dt v-text="detail.title.rendered"></dt>
									<dd class="description" v-text="detail.acf.one"></dd>
								</dl>
							</div>
							<h2><i class="fas fa-comment-dots rvs"></i>先輩社員インタビュー</h2>
							<ul class="faq">
								<li>
									<dl>
										<dt>現在の仕事内容を教えてください</dt>
										<dd v-html="detail.acf.work"></dd>
									</dl>
								</li>
								<li>
									<dl>
										<dt>入社を決意した決め手はなんですか</dt>
										<dd v-html="detail.acf.kimete"></dd>
									</dl>
								</li>
								<li>
									<dl>
										<dt>仕事のやりがいはなんですか</dt>
										<dd v-html="detail.acf.yarigai"></dd>
									</dl>
								</li>
								<li>
									<dl>
										<dt>入社後、一番印象的だった出来事はなんですか</dt>
										<dd v-html="detail.acf.insyo"></dd>
									</dl>
								</li>
								<li>
									<dl>
										<dt>入社を目指す方へのメッセージ</dt>
										<dd v-html="detail.acf.mesage"></dd>
									</dl>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</transition>
		</div>
		`
	}
	
	return compornent;
}