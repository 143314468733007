export function firstview(){
    const swipeOption = {
		loop: false,
		effect: 'fade',
		autoplay: {
			delay: 8000,
			stopOnLastSlide: true,
			disableOnInteraction: false,
		},
		speed: 2000,
    }
	
	const swiper = new Swiper('#firstview .swiper-container' , swipeOption);
	return swiper;
}