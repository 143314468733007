export function works(rest){
	const compornent = {
		data: function(){
			return {
				toggle: false,
				data:  [],
				detail: []
			}
		},
		created: function(){
            const $_this = this;
            axios.get(rest)
                .then(function (response) {
                    $_this.data = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
		},
		methods: {
			open : function(index){
				this.detail = this.data[index];
				this.toggle = true;
			},
			changing: function(){
				const body = document.getElementById('site-body');
				const head = document.getElementById('site-header');
				body.classList.toggle('hidden');
				head.classList.toggle('hidden');
			}
		},
		template:
		`
		<div>
			<ul class="listable">
				<li @click="open(index) , changing()" v-for="(items , index) in data">
					<div class="bg" :style="{ 'background-image': 'url('+items.acf.photo+')' }"></div>
					<h3 v-text="items.title.rendered"></h3>
				</li>
			</ul>

			<transition name="move">
				<div class="overlay white" v-if="toggle">
					<div class="close" @click="toggle = false , changing()"></div>
					<div class="inner works-component">
						<div class="contanier">
							<div class="profile">
								<div class="bg" :style="{ 'background-image': 'url('+detail.acf.photo+')' }"></div>
								<dl>
									<dt v-text="detail.title.rendered"></dt>
									<dd v-html="detail.acf.kani"></dd>
								</dl>
							</div>
							<h2><i class="fas fa-briefcase"></i>業務内容詳細</h2>
							<p class="txt" v-html="detail.acf.mesage"></p>
						  </div>
					</div>
				</div>
			</transition>
		</div>
		`
	}
	
	return compornent;
}