import {firstview} from './firstview';
export function catchs(rest){
	const compornent = {
		data: function(){
			return {
				data:  {
					acf : {
						catch : '',
						sub : '',
						main_01: '',
						main_02: '',
						main_03: ''
					}
				}
			}
		},
		created: function(){
            const $_this = this;
            axios.get(rest)
                .then(function (response) {
                    $_this.data = response.data;
                    firstview();
                })
                .catch(function (error) {
                    console.log(error);
                });
		},
		template:
		`
		<div id="firstview">
			<div class="concept">
				<dl>
					<dt>{{ data.acf.catch }}</dt>
					<dd>{{ data.acf.sub }}</dd>
				</dl>
			</div>			
			<div class="swiper-container">
				<div class="swiper-wrapper">
					<div class="swiper-slide">
						<div class="swipe-bg" :style="{ 'background-image': 'url('+data.acf.main_01+')' }"></div>
					</div>
					<div class="swiper-slide">
						<div class="swipe-bg" :style="{ 'background-image': 'url('+data.acf.main_02+')' }"></div>
					</div>
					<div class="swiper-slide">
						<div class="swipe-bg" :style="{ 'background-image': 'url('+data.acf.main_03+')' }"></div>
					</div>
				</div>
			</div>
			<div class="scroll"><i>SCROLL PAGE</i></div>
		</div>
		`
	}
	return compornent;
}