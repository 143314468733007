export function privacy(){
	const compornent = {
		template:
		`
        <div class="privacy">
            <p>当社は、個人情報の保護に関する法令等を遵守するとともに、社会的責務であると考え、以下にプライバシーポリシーを定め、全ての役員、社員、協働者に周知し、徹底を図ることにより、個人情報の適正な取り扱いに努めてまいります。</p>
            <dl>
                <dt>法令の遵守</dt>
                <dd>当社は個人情報を取り扱うにあたり、個人情報の保護に関する法律および関係する法令、ガイドラインを遵守します。</dd>
            </dl>
            <dl>
                <dt>個人情報の収集</dt>
                <dd>当社は、個人情報をご提供いただく場合、個人情報の利用目的の公表またはご本人への通知により、その利用目的を明らかにいたします。ご提供いただいた個人情報の利用目的を変更する場合は、変更後の利用目的を公表、またはご本人へ通知いたします。</dd>
            </dl>
            <dl>
                <dt>個人情報の管理・保護</dt>
                <dd>ご提供いただいた個人情報は、管理責任者が適切な管理を行なうとともに、漏洩や紛失等の防止に努めます。また、外部からの不正アクセスや破壊、改ざん等の危険に対して安全対策を実施し、個人情報の保護に努めます。</dd>
            </dl>
            <dl>
                <dt>個人情報の利用</dt>
                <dd>当社は、ご本人の同意を得ないで、利用目的の達成に必要な範囲を超えて個人情報を取り扱いいたしません。</dd>
            </dl>
            <dl>
                <dt>個人情報の第三者への提供</dt>
                <dd>当社は、ご本人の同意を得ないで、業務委託先以外の第三者に開示、提供することはありません。ただし、法令により開示を求められた場合を除きます。</dd>
            </dl>
            <dl>
                <dt>個人情報の開示・訂正等</dt>
                <dd>個人情報につき開示、訂正等の請求があった場合は、その請求がご本人またはその代理人によるものであることを確認した上で、必要かつ合理的な範囲で対応いたします。</dd>
            </dl>
            <dl>
                <dt>社内体制の整備</dt>
                <dd>当社は、個人情報の適正な取り扱いを目的とした体制の整備および社内の教育の継続的実施に努めてまいります。</dd>
            </dl>
        </div>
		`
	}
	
	return compornent;
}