export function business(rest){
	const compornent = {
		data: function(){
			return {
				toggle: false,
				data:  {
					acf : {
						title : '',
						description : '',
						image: ''
					}
				}
			}
		},
		created: function(){
            const $_this = this;
            axios.get(rest)
                .then(function (response) {
                    $_this.data = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
		},
		methods: {
			changing: function(){
				const body = document.getElementById('site-body');
				const head = document.getElementById('site-header');
				body.classList.toggle('hidden');
				head.classList.toggle('hidden');
			}
		},
		template:
		`
		<div>
			<div class="relative">
				<div class="bg" :style="{ 'background-image': 'url('+data.acf.image+')' }"></div>
				<div class="display texter">
					<dl>
						<dt v-html="data.acf.title"></dt>
						<dd v-html="data.acf.description"></dd>
						<dd class="btns">
							<a @click="toggle = true , changing()" class="custum-bg">代表プロフィール</a>
						</dd>
					</dl>
				</div>
			</div>
            <transition name="move">
				<div class="overlay white" v-if="toggle">
					<div class="close" @click="toggle = false , changing()"></div>
					<div class="inner">
						<div class="contanier">
							<h2><i class="fas fa-user-tie"></i>代表挨拶</h2>
							<div class="repbox">
								<div class="bg" :style="{ 'background-image': 'url('+data.acf.rep_photo+')' }"></div>
								<dl>
									<dd v-html="data.acf.rep_message"></dd>
									<dt>
										<small v-html="data.acf.rep_job"></small>
										<strong v-html="data.acf.rep"></strong>
									</dt>
								</dl>
							</div>

							<h2><i class="fas fa-building"></i>会社概要</h2>
							<table class="table-style">
								<tbody>
									<tr>
										<th>商号</th>
										<td v-html="data.acf.syogo"></td>
									</tr>
									<tr>
										<th>設立</th>
										<td v-html="data.acf.build"></td>
									</tr>
									<tr>
										<th>代表取締役</th>
										<td v-html="data.acf.ceo"></td>
									</tr>
									<tr>
										<th>事業内容</th>
										<td v-html="data.acf.business"></td>
									</tr>
									<tr>
										<th>所在地</th>
										<td v-html="data.acf.address"></td>
									</tr>
									<tr>
										<th>電話番号</th>
										<td v-html="data.acf.tel"></td>
									</tr>
									<tr>
										<th>FAX番号</th>
										<td v-html="data.acf.fax"></td>
									</tr>
								</tbody>
							</table>
						</div>		
					</div>
				</div>
			</transition>

		</div>
		`
	}
	return compornent;
}