export function news(rest){
	const compornent = {
		data: function(){
			return {
				toggle: false,
				data:  []
			}
		},
		created: function(){
            const $_this = this;
            axios.get(rest)
                .then(function (response) {
                    $_this.data = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
		},
        computed: {
			limitCount() {
				return this.data.slice(0,1);
			}
        },
		methods: {
			set_date(date){
				let substr = date.substring(0,10);
				return substr.replace(/-/g, '.');
			}
		},
		template:
		`
		<div>
			<div class="news-block custum-bg" @click="toggle = true">
				<div class="timeline">
					<template v-for="data of limitCount">
					<time>{{ set_date(data.date) }}</time>{{ data.title.rendered }}
                    </template>
				</div>
				<i class="fas fa-arrow-circle-right more"></i>
			</div>

			<transition name="scale">
                <div class="overlay" v-if="toggle">
                    <div class="close" @click="toggle = false"></div>
                    <div class="inner">
                        <h3>お知らせ</h3>
                        <div class="news-component">
                            <ol>
                                <li v-for="( items , index ) in data">
                                    <time class="custum-bg">{{ set_date(items.date) }}</time>
                                    <dl>
                                        <dt>{{ items.title.rendered }}</dt>
                                        <dd v-html="items.acf.context"></dd>
                                    </dl>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
			</transition>
		</div>
		`
	}
	
	return compornent;
}