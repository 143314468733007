export function sns(rest){
	const compornent = {
		data: function(){
			return {
				data:  {
					acf : {
						twitter:'',
						facebook:'',
						instagram:'',
						youtube:''
					}
				}
			}
		},
		created: function(){
            const $_this = this;
            axios.get(rest)
                .then(function (response) {
                    $_this.data = response.data;
				})
                .catch(function (error) {
                    console.log(error);
                });
		},
		template:
		`
          <ul class="sns clearfix">
              <li v-if="data.acf.twitter != '' "><a :href="data.acf.twitter" target="_blank" class="custum-color"><i class="fab fa-twitter"></i></a></li>
              <li v-if="data.acf.facebook != '' "><a :href="data.acf.facebook" target="_blank" class="custum-color"><i class="fab fa-facebook-f"></i></a></li>
              <li v-if="data.acf.instagram != '' "><a :href="data.acf.instagram" target="_blank" class="custum-color"><i class="fab fa-instagram"></i></a></li>
              <li v-if="data.acf.youtube != '' "><a :href="data.acf.youtube" target="_blank" class="custum-color"><i class="fab fa-youtube"></i></a></li>
          </ul>
		`
	}
	
	return compornent;
}