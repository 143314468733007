export function logo(rest){
	const compornent = {
		data: function(){
			return {
				data:  {
					acf : {
						logo:'',
						title:'',
						company:'',
						url:'',
						color: ''
					}
				}
			}
		},
		created: function(){
            const $_this = this;
            axios.get(rest)
                .then(function (response) {
                    $_this.data = response.data;
					const app = document.getElementById('app');
					app.classList.add($_this.data.acf.color);
                })
                .catch(function (error) {
                    console.log(error);
                });
		},
		template:
		`
		<div class="logo clearfix">
			<a :href="data.acf.url"><img :src="data.acf.logo" :alt="data.acf.company" /></a>
			<h1 v-html="data.acf.title"></h1>
		</div>
		`
	}
	
	return compornent;
}