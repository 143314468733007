export function kussion(rest){
	const compornent = {
		data: function(){
			return {
				data:  {
					acf : {
						catch : '',
						sub : '',
						photo: ''
					}
				}
			}
		},
		created: function(){
            const $_this = this;
            axios.get(rest)
                .then(function (response) {
                    $_this.data = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
		},
		template:
		`
        <section class="site-module site-kussion" :style="{ 'background-image': 'url('+data.acf.photo+')' }">
            <div class="display">
                <dl>
                    <dt v-text="data.acf.catch"></dt>
                    <dd v-html="data.acf.sub"></dd>
                    <dd>
                        <slot></slot>
                    </dd>
                </dl>
            </div>
        </section>
		`
	}
	return compornent;
}