export function maps(rest){
	const compornent = {
		data: function(){
			return {
				data:  {
					acf : {
						map : '',
					}
				}
			}
		},
		created: function(){
            const $_this = this;
            axios.get(rest)
                .then(function (response) {
                    $_this.data = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
		},
		template:
		`
        <div class="googlemap" v-html="data.acf.map">
        </div>
		`
	}
	return compornent;
}