export function foot(rest){
	const compornent = {
		data: function(){
			return {
				data:  {
					acf : {
						post : '',
						addr : '',
						tel : '',
						fax : ''
					}
				}
			}
		},
		created: function(){
            const $_this = this;
            axios.get(rest)
                .then(function (response) {
                    $_this.data = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
		},
		template:
		`
          <footer id="site-footer" role="contentinfo" itemscope="itemscope" itemtype="http://schema.org/WPFooter">
              <a id="pageup" href="#app">▲ PAGE TOP</a>

              <div class="meta display">
                  <div class="data">〒{{ data.acf.post }} {{ data.acf.addr }}<br>TEL.{{ data.acf.tel }}  / FAX.{{ data.acf.fax }}</div>
                  <slot></slot>
              </div>
          </footer>
		`
	}
	return compornent;
}